<template>
  <section class="tiss-container">
    <InvoiceHeaderContainer
      :title="title"
      :subtitles="subtitles"
      :invoiceSelected="invoiceSelected"
      :invoiceStatus="invoiceStatus"
      :handleInvoiceSituation="handleInvoiceSituation"
      :startGenerateLotsBySelectionProp="startGenerateLotsBySelection"
      :stopGenerateLotsBySelectionProp="stopGenerateBySelection"
      :selecteds="selecteds"
      :selectedType="selectedType"
      @generateLotsByAssociation="generateLotsByAssociation"
      @generateLotsBySelection="generateLotsBySelection"
      @setSelectedType="setSelectedType($event)"
      :key="reloadInvoice"
    />
    <CardInvoiceContainer :resume="resume" />
    <InvoiceFilter @change="onChangeFilter" class="no-print" />
    <GuidesTable
      canRemove
      :headers="headers"
      :subHeaders="subHeaders"
      :rows="rows"
      :getGuide="getGuide"
      :openHistoric="openHistoric"
      :openDocumentModal="openDocumentModal"
      :openDocumentListModal="openDocumentListModal"
      :loading="loading"
      :generateByselection="generateByselection"
      :selecteds="selecteds"
      @setSelectedType="setSelectedType($event)"
      @onRemoveGuide="onRemoveGuide"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { TissInvoice } from '@/modules/tiss/manageGuides/utils/statuses'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import map from 'lodash/map'

export default {
  name: 'InvoiceContainer',
  components: {
    CardInvoiceContainer: () => import('@/modules/tiss/manageGuides/layouts/CardInvoiceContainer'),
    GuidesTable: () => import('@/components/Tiss/Table/GuidesTable'),
    InvoiceHeaderContainer: () => import('@/modules/tiss/manageGuides/components/InvoiceHeaderContainer'),
    InvoiceFilter: () => import('@/components/Tiss/Generic/Filters/InvoiceFilter'),
  },
  props: {
    title: String,
    subtitles: Object,
    invoiceSelected: Object,
    getGuide: Function,
    openHistoric: Function,
    openDocumentModal: Function,
    formatDate: Function,
    getTypes: Function
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      generateByselection: false,
      selecteds: [],
      selectedType: null,
      rows: [],
      resume: null,
      cards: [],
      invoiceStatus: '',
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      filters: [],
      headers: [
        'Data criação',
        'Paciente',
        'N° da guia',
        'Profissional exec.',
        'Prazo envio',
        'Tipo de guia',
        'Faturado',
        'Lote',
        'Conferido',
        'Ver menos'
      ],
      subHeaders: [
        'Atualização',
        'Tabela',
        'Cód. Item',
        'Descrição',
        'Valor faturado',
        'Valor pago'
      ],
      loading: false,
      reloadInvoice: 1
    }
  },
  async mounted() {
    await this.getGuides()
    this.invoiceStatus = this.invoiceSelected.status
  },
  methods: {
    parseNumberToMoney,
    openDocumentListModal(tissGuide){
      this.$emit('openDocumentListModal', tissGuide)
    },
    changeInvoiceStatus(status){
      this.invoiceStatus = status
    },
    async handleInvoiceSituation() {
      const props = {
        status: this.invoiceStatus === TissInvoice.INVOICE_STATUS_OPEN ? 'Fechada' : TissInvoice.INVOICE_STATUS_OPEN
      }
      const isLoading = this.$loading.show()
      try {
        await this.api.updateInvoicingTissInvoice(this.invoiceSelected.id, props)
        this.changeInvoiceStatus( this.invoiceStatus === TissInvoice.INVOICE_STATUS_OPEN ? 'Fechada' : TissInvoice.INVOICE_STATUS_OPEN )
        this.$toast.success('Situação atualizada com sucesso!')
      } catch (error) {
        this.$toast.warning(error.message)
        this.changeInvoiceStatus( TissInvoice.INVOICE_STATUS_OPEN )
        this.invoiceSelected.status = TissInvoice.INVOICE_STATUS_OPEN
        this.reloadInvoice++
      } finally {
        isLoading.hide()
      }
    },
    async getGuides() {
      this.loading = true
      try {
        const { data } = await this.api.getTissGuideByInvoiceId(
          this.invoiceSelected.id,
          this.currentPage,
          this.filters
        )

        this.currentPage = data.guides.current_page
        this.perPage = data.guides.per_page
        this.totalItems = data.guides.total
        this.rows = []
        this.resume = data.resume

        if (data.guides.data.length) {
          this.fillArray(data.guides.data)
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    fillArray(row) {
      let professionals = []
      row.map(row => {
        row.items.map( item => {
          item.professionals.map(professional => {
            if (!professionals.find(el => el === professional.professional.name)) {
              professionals.push(professional.professional.name)
            }
          })
        })
        const guideRow = {
          id: row.id,
          clinic_health_plan_id: row.clinic_health_plan_id,
          files_count: row.files_count,
          'Data criação': this.formatDate(row.created_at),
          'Paciente': row?.patient?.name,
          'N° da guia': row?.provider_guide ? row.provider_guide : '-',
          'Profissional exec.': professionals.length ? professionals : 'Não informado',
          'Prazo envio': this.formatDate(row.created_at),
          'Tipo de guia': this.getTypes(row.guide_type),
          'Faturado': this.parseNumberToMoney(row.items.reduce((acc, item) => {
              if(parseFloat(item.total_value) > 0){
                return acc + item.total_value;
              }else{
                return acc + item.unitary_value * item.quantity;
              }
          }, 0)),
          'Lote': row.tiss_batch_id ? 'Sim' : 'Não',
          'Conferido': Boolean(row.conferred),
          'Detalhes': [],
          'Nº Fatura': row.tiss_batch ? row.tiss_batch.number : null,
          'Id Config': row.invoicing_tiss_setting_id
        }

        if(!guideRow.files_count)
          guideRow.files = []

        let items = row.items.map(item => ({
          Situação: item.status,
          Atualização: this.formatDate(item.updated_date),
          Tabela: item.table_name,
          'Cód. Item': item.item ? item.item.code : '-',
          Descrição: item.description ?? item?.item?.name,
          'Valor faturado': this.parseNumberToMoney(item.total_value <= 0 ? (item.unitary_value * item.quantity) : item.total_value),
          'Valor pago': this.parseNumberToMoney(item.value_paid)
        }))

        const expensableGuides = map(row.guide.expensables, 'guide').filter(el => el)
        const itemsMap = map(expensableGuides, 'items')

        let expenseItems = {}

        itemsMap.forEach(items => {
          expenseItems = items.map(item => ({
            Situação: item,
            Atualização: this.moment(item.updated_date).format('DD/MM/YYYY'),
            Tabela: item.table_name,
            'Cód. Item': item.item ? item.item.code : '',
            Descrição: item.description,
            'Valor faturado': this.parseNumberToMoney(item.total_value <= 0 ? (item.unitary_value * item.quantity) : item.total_value),
            'Valor pago': this.parseNumberToMoney(item.value_paid)
          }))
        })

        if(Object.keys(expenseItems).length) items = items.concat(expenseItems)

        const summaryGuides = map(row.guide.hospitalization_summaries, 'guide')
        const summaryItemsMap = map(summaryGuides, 'items')

        let itemsSummary = {}

        summaryItemsMap.forEach(items => {
          itemsSummary = items.map(item => ({
            Situação: item.status,
            Atualização: this.moment(item.updated_date).format('DD/MM/YYYY'),
            Tabela: item.table_name,
            'Cód. Item': item.item ? item.item.code : '',
            Descrição: item.description,
            'Valor faturado': this.parseNumberToMoney(item.total_value <= 0 ? (item.unitary_value * item.quantity) : item.total_value),
            'Valor pago': this.parseNumberToMoney(item.value_paid)
          }))
        })
        if (Object.keys(itemsSummary).length) items = items.concat(itemsSummary)

        guideRow.Detalhes = items
        this.rows.push(guideRow)
        professionals = []
      })
    },
    async onChangeFilter(filter) {
      this.filters = filter
      await this.getGuides()
    },
    generateLotsByAssociation(invoice) {
      this.$emit('generateLotsByAssociation', invoice)
    },
    startGenerateLotsBySelection(){
      this.generateByselection = true
      this.selecteds = []
      this.headers.unshift('')
    },
    stopGenerateBySelection(){
      this.generateByselection = false
      this.selecteds = []
      this.selectedType = null
      this.headers.shift()
    },
    generateLotsBySelection(props) {
      this.$emit('generateLotsBySelection', props)
    },
    setSelectedType(type) {
      this.selectedType = type
    },
    async onRemoveGuide(tissGuide) {
      const isLoading = this.$loading.show()
      try {
        await this.api.updateNewTissGuide(tissGuide.id, { tiss_invoice_id: null, tiss_batch_id: null, clinic_id: this.clinic.id })
        this.$toast.success('Guia removida com sucesso')
        await this.getGuides()
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    }
  },
  watch: {
    invoiceSelected: {
      handler: async function () {
        await this.getGuides()
      },
      deep: true
    },
    currentPage: {
      handler: async function () {
        await this.getGuides()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tiss-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid var( --neutral-300);
  border-radius: 8px;
  margin-bottom: 30px;
}
</style>
